#no-scroll::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.singlepost-image{ 
    width: 100%;
   
}

.post-comment-container{
    padding-top: 2rem;
    background-color: #f6f9ff;
	border-radius: 5px;
	box-shadow: 0 30px 50px -20px #ccc;
    overflow: hidden;
    margin-right: 190px;
}

.post-icon{
    height: 16px;
}

.post-info  {
    font-size: medium;
    font-weight: 300;
    font-style: normal;
    
    
}

@media screen and (max-width: 768px) {
    .singlepost-image{
        width: 100%;
        height: 100%;
    }

    .post-content-container{
        padding-left: 7%;
    }
}

.wrapper{
    background-color: #f6f9ff;
	border-radius: 5px;
	box-shadow: 0 30px 50px -20px #ccc;
}

.share-icon{
    margin-left: 30px;
}

i.fa{
    color: black !important;
}

.accordian-button_c {
	color: #022c56;
}

.accordian-button_c:focus {
	box-shadow: none;
	background-color: #fff;
	color: #022c56;
}

.list-item-c {
	border: none !important;
	cursor: pointer !important;
}

.list-item-c:checked {
	background: #022c56 !important;
	color: white !important;
}

i.fa:hover{
	color: #0456a8 !important;
}

.like-container{
    display: flex;
   justify-content: center;
}
.like-container p{
    width: auto;
    height: auto;
    margin-left: 5px;
    margin-bottom: 4px;
    padding-top: 0px;   
    margin-top: 0;
}


.googlemap-icon img{
    width: 25px;
}

.namebeside-profile{
    margin-bottom: 0;
    margin-top: 12px;
    
  
}

.namebeside-profile>span{
    margin-left: 0;
}

.post-content-container .water-mark{
	margin-bottom: 10px;
	font-size: x-small;
	/* color: rgb(88, 24, 109); */
    color: rgb(232, 232, 232);
	margin-left: 5px;
	font-weight: 550;
    outline: none;
    border: none;
	 text-shadow: 0 0 2px rgba(149, 148, 149, 0.5); 
    margin-left: 20px;
}
.post-comment-container .water-mark p{
	text-align: left;
	

}

.content-comment-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.singlepost-image-container .singlepost-image{
    border-radius: 10px;
    width: 100%;
    height: 35vw;
    
}

.singlepost-image-container{
    width: 100%;
    height: 35vw;
    position: relative;
   
   
}



h5.singlepost-title{
    color: black;
    font-weight: 550;
    margin-top: 10px;
    margin-bottom: 0px;

}

p.singlepost-tag{
    margin-top: 0px;
    font-size: medium;
}

.singlepost-header{
    margin-left: 110px;
   
}
.fixed-bottom-container {
    position: fixed;
    bottom: 10px;
    width: 50%;
    min-width: 300px;
   padding-left: 5%;
   padding-right: 5%    ;
    padding-top: 1.5%;
    margin-bottom: 0;
    padding-bottom: 10px;
     /* Adjust as needed */
    z-index: 1000; /* Ensures it stays above other content */
    left: 25%;
   

  }
  

  .like-reply-container{
    font-size: smaller;
    color: gray;
  }
 

.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.custom-modal-content {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.custom-modal-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.required-field-values {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .required-field-key {
    font-weight: bold;
    color: #333;
    margin-right: 5px;
  }
  
  .required-field-value {
    color: #555;
    font-style: italic;
  }
  
  .required-field-item {
    margin-bottom: 10px;
  }
  