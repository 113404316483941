.apk-download-container {
	display: flex;
	align-items: center;
	align-items: center;
	height: 4em;
}

.apk-download-container .bi-android2 {
	font-size: 30px; /* Adjust size as needed */
	margin-right: 10px; /* Spacing between icon and button */
}

.btn-download-apk {
	display: inline-block;
	padding: 6px 20px;
	font-size: 15px;
	color: white;
	background-color: black;
	text-decoration: none;
	border-radius: 5px;
	margin-left: 12px;
}

/* .basic-slider{
    background-color: white;
} */

.btn-download-apk:hover {
	background-color: rgb(48, 48, 48);
}

.hero h3 {
	font-size: 35px;
}
.apk-link {
	text-decoration: none;
	color: white;
	outline: none;
	border: none;
}
