.admin-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f6f9ff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  width: 100%;
  max-width: 900px;
  margin-top: 150px;
}

.admin-panel-bg {
  min-height: 100vh;
  background-color: #f8f9fa;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.admin-panel-button {
  padding: 10px 20px;
  margin-bottom: 20px;
  font-size: 16px;
  background-color: #03276e;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.admin-panel-button:hover {
  background-color: #003399;
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
}

.form-group label {
  display: inline-block;
  
  margin-bottom: 5px;
  font-weight: bold;
}


.form-group input[type="text"],
.form-group input[type="file"] {
 width: auto;
 margin-left: 10px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  display: inline-block;
  /* outline: solid black ; */
}

.form-group input[type="text"]:focus,
.form-group input[type="file"]:focus {
  border-color: #0044cc;
  outline: none;
}

.btn-primary {
  background-color: #03276e;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #003399;
}

.alert {
  margin-top: 20px;
  padding: 10px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.accept-button {
  background-color: #28a745;
  border: solid;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.accept-button:hover {
  background-color: #218838; /* Darker green for hover effect */
  border-color: #218838; /* Change border color on hover */
}

.reject-button {
  background-color: #e02639;
  color: white;
  border: solid;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reject-button:hover {
  background-color: #c82333; /* Darker red for hover effect */
  border-color: #c82333; /* Change border color on hover */
}

.navigation-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.admin-panel-button.active {
  background-color: #003399;
}

.authorities-list {
  list-style-type: none;
  padding: 0;
}

.authority-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f6f9ff;
  border: 1px solid #ccc;
  border-radius: 5px;
}
