.user-contact {
    background: url("/src/Media/Images/ContactBackground.png") center center no-repeat;
    background-size: cover;
    width: 101vw;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-items: center;
    overflow-x: hidden;
    font-size: 12pt;
    color: #2A293E;
}

.msp-lab {
    background: url("https://web.iitd.ac.in/~lalank/msp/images/iitd_header.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-height: 45vh;
    height: 45vh;
    width: 101vw;
    overflow-x: hidden;
}

.contact-us {
    padding: 50px 100px;
    width: 50vw;
    background-color: #f6f9ff;
    border: transparent;
    border-radius: 5px;
    box-shadow: 0 30px 50px -20px rgba(204, 204, 204, 0.433);
}

.input {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0;
}

.contact-us textarea {
    background-color: #cccccc67;
    padding: 0;
    border: 2px;
    outline: 0;
}

input[type="submit"] {
    cursor: pointer;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input::-moz-placeholder,textarea::-moz-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input:-ms-input-placeholder, textarea::-ms-input-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input::placeholder, textarea::placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

.contact-us .forms_title {
    margin-bottom: 45px;
    font-size: 2.5rem;
    font-weight: 550;
    line-height: 1em;
    text-transform: uppercase;
    color: #022c56;
    letter-spacing: 0.1rem;
    animation: loadProfile ease-in-out 1.5s;
}

.contact-us .forms_field:not(:last-of-type) {
    margin-bottom: 20px;
}

.contact-us .forms_field-input, .contact-us textarea {
    width: calc(100% - 35px);
    border-bottom: 1px solid #ccc;
    padding: 6px 20px 6px 6px;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: gray;
    letter-spacing: 0.1rem;
    -webkit-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
}

.contact-us .forms_field-input:focus, .contact-us textarea:focus {
    border-color: gray;
}

.contact-us .forms_buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 35px;
}

.contact-us .forms_buttons-action {
    width: 100%;
    background-color: #022c56;
    border-radius: 3px;
    padding: 10px 35px;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
}

.contact-us .forms_buttons-action:hover {
    background-color: #044485;
}

@media screen and (max-width: 990px) {
    .user_options-forms {
        min-height: 350px;
    }

    .user_options-forms .forms_buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .user_options-forms .user_forms-login .forms_buttons-action {
        margin-top: 30px;
    }

    .user_options-forms .user_forms-login {
        top: 40px;
    }

    .user_options-unregistered {
        padding: 50px 45px;
    }

    .contact-us {
        padding: 30px 30px;
        width: 60vw;
    }
}

@media (max-width: 768px) {

    .user {
        width: 100vw;
        height: 110vh;
    }

    .user_options-container {
        position: static;
        width: 100vw;
        height: 100vh;
        margin-bottom: 80vh;
    }

    .user_options-forms {
        position: relative;
        height: 60vh;
        width: 100vw;
        top: 30vh;
        left: -100%;
        border-radius: 0px;
        box-shadow: none;
    }

    .user_options-text {
        position: relative;
        height: 40vh;
        width: 100vw;
        top: 100vh;
        left: 0%;
    }

    .user_options-unregistered {
        width: 100%;
    }

    .contact-us {
        padding: 10px 10px;
        width: 80vw;
    }
}

@keyframes loadTop {
    from {
        transform: translateX(100px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}