.footer-inner {
	background-color: #022c56;
	display: table;
	width: 100%;
	color: #fff;
	padding: 20px;
	
}

.footer-inner p {
	float: none !important;
	width: 100%;
	display: block;
	text-align: center;
	margin-bottom: 10px;
}

.footer-fixed {
	position: fixed;
	bottom: 0;
	width: 100%;
}

.copyright button{
	font-size: medium;
	color: #fff;
	font-weight: 400;
}

.copyright a:hover{
	font-size: medium;
	color: #0456a8;
	font-weight: 400;
	cursor: pointer;
}

.privacy-delete-container {
	display: flex;
	font-size: small;
	justify-content: space-between;
}

.privacy-delete-container button{
	font-size: small;
	align-items: start;
}