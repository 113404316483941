body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
    
    
    height: 100vh;
    margin: 0;
}

.deleteAccount-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
    
}
.deleteAccount-container h1{
    text-align: start;
}


.deleteAccount-container p {
    line-height: 1.6;
    text-align: start;

}

.deleteAccount-container ol {
    list-style-type: disc;
    text-align: left;
    
    padding-left: 20px;
}

.deleteAccount-container .warning {
    color: #e74c3c;
    font-weight: bold;
}

