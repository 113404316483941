.admin-panel-bg {
    min-height: 100vh;
    background-color: #f8f9fa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
  }
  .content-area{
   min-width: 800px;
    
  }
  
  .response-message {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: #495057;
  }
  
  .admin-container{
    display: flex;
  }
  
  
  .navigation-bar {
    height: 100%;
    padding: 10px;
    margin-top: 100px;
    margin-right: 50px;
    display: flex;
    width: 250px;
    align-items: flex-start;
    flex-direction: column;
  }
  @media screen and (max-width:960px) {
    .content-area{
      min-width: 200px;
       
     }
     .navigation-bar{
      width: auto;
      margin-right: 0;
     }
  }