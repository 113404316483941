a {
	text-decoration: none;
	color: #fff;
}

button {
	cursor: pointer;
}

input:focus {
	outline: none;
}

img {
	/* user-drag: none; */
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	cursor: pointer;
}

/*.left {
	 border-right: 2px solid #022c5617;
} */

.left-navbar {
	height: 100%;
	margin-top: 20px;
	margin-right: 0px;
	/* margin-left: 50px; */
	display: flex;
	/* align-items: center; */
	flex-direction: column;
	font-family: en_regular, Arial, sans-serif;
}

.left i.fa,
.content-header i.fa,
.post-header i.fa,
.trends i.fa {
	color: #022c56 !important;
}

.left i.fa{
	width: 30px;
	font-size: 20px;
	
}

.left-navbar a {
	display: flex;
	padding: 4px 0;
	cursor: pointer;
}


.left-navbar a div {
	display: flex;
	padding: 6px;
	align-items: center;
	font-size: 10px;
	/* color: #022c56; */
	color: black;

	
}

.left-navbar a div span.active {
	color: #f63d3d;
}


.left-navbar a div:hover {
	background-color: #022c5619;
}

.left-navbar a div:hover i.fa,
.left-navbar a div:hover span {
	color: #000;
	fill: #000;

}

.left-navbar a span {
	font-size: 17px;
	font-weight: 550;
	margin-left: 5px;
	margin-right: 16px;
}


.left .right-column {
	display: flex;
	align-items: center;
}


.content-header {
	padding: 0 16px;
	display: flex;
	align-items: center;
	height: 53px;
	justify-content: space-between;
	border-bottom: 2px solid #022c5617;
}

.content-header div,
.trends-header div,
.trends-item>div>div {
	display: flex;
	margin-right: -10px;
	border-radius: 100%;
	align-items: center;
	justify-content: center;
	width: 38px;
	height: 38px;
	cursor: pointer;
}

.trends-header div {
	margin-right: 0;
}

.trends-item>div {
	position: relative;
}

.trends-item>div>div {
	position: absolute;
	width: 34.75px;
	height: 34.75px;
	top: -7px;
	right: 0px;
}

.content-header div:hover,
.trends-header div:hover,
.trends-item>div>div:hover {
	background-color: #022c5627;
}

.trends-item>div>div:hover i.fa {
	fill: var(--main-color);
}

.trends-header i.fa {
	fill: #022c56 !important;
	color: #022c56 !important;
}

.trends-item svg,
.trends-header i.fa {
	vertical-align: text-bottom;
}

.content-header span,
.trends-header span {
	font-size: 20px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
	font-weight: 900;
}

.content-header i.fa {
	fill: #022c56;
	color: #022c56 !important;
	width: 22px;
	height: 22px;
	padding-top: 8px;
}

.content-header,
.tweet,
.post {
	padding: 15px;
}

.tweet {
	display: flex;
	border-bottom: 1px solid var(--border-color);
}

.post:hover {
	cursor: pointer;
	background-color: rgba(255, 255, 255, 0.07);
}


.profile-btn {
	display: flex;
	margin: 12px 0;
	padding: 12px;
	cursor: pointer;
	width: calc(100% + 12px);
	border-radius: 9999px;
}

i.bi{
	color: #000;
}
.profile-btn:hover {
	background-color: var(--shadow-color);
}

.profile-btn img {
	width: 40px;
	height: 40px;
}

.name-time > strong{
	align-self: first baseline;
}
.profile-btn strong {
	display: flex;
	align-items: center;
}

.details i.fa ,
.details i.bi{
	height: 32px;
	margin-left: 5px;
	vertical-align: bottom;
	padding: 8px;
	padding-top: 16px;
}

.time{
	color: gray;
	fill: gray;
	align-self: first baseline;
}

.tag,
.details i.fa,
.details i.bi {
	color: black !important;
	fill: black !important;
	display: block;
}

.right-column {
	margin-left: 10px;
}

.right-column,
.right-column form,
.right-column input {
	width: 100%;
}

.name-time{
	display: flex;
	flex-direction: column;
}
.profile-image {
	border-radius: 100%;
	width: 48px;
	height: 48px;
}

.right-column input {
	height: 56px;
	background-color: transparent;
	border: none;
}

.right-column input::placeholder,
.search::placeholder {
	font-size: 20px;
	color: #8899A6;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.tweet .right-column .bottom-row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;
}

.tweet .right-column .bottom-row .buttons>div {
	width: 38px;
	height: 38px;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tweet .right-column .bottom-row .buttons {
	display: flex;
}

.tweet .right-column .bottom-row .buttons>div:hover {
	background: var(--shadow-color);
}

.tweet .right-column .bottom-row i.fa {
	width: 22px;
	height: 22px;
	fill: var(--main-color);
}

.tweet .right-column .bottom-row .tweet-btn {
	width: 90px;
	opacity: 0.5;
	margin: 0;
}

.space {
	width: 100%;
	height: 12px;
	background-color: rgb(25, 39, 52);
	border-bottom: 1px solid var(--border-color);
}

.post {
	display: flex;
	border-bottom: 2px solid #022c5617;
	width: 100%;
}

.top-row>p,
.more-btn>span {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 8px;
}
.top-row>p{
	font-weight: 550;
	margin-bottom: 2px;
}
.more-btn>p {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 4px;
}

.post-header {
	display: flex;
	justify-content: space-between;
}

.post-header>div {
	display: flex;
	align-items: center;
}

.dot {
	line-height: 20px;
	font-size: 15px;
	margin-top: -6px;
}

.details {
	cursor: pointer;
	padding: 6px;
	border-radius: 100%;
	justify-content: center !important;
}

.details i.fa ,
.details i.bi{
	margin: 0;
}

.details:hover {
	background-color: #022c561b;
}

.details:hover i.fa 
.details:hover i.bi{
	fill: var(--main-color);
}

.post-header .tag,
.dot {
	margin-left: 4px;
}

.post .bottom-row {
	margin-top: 4px;
	justify-content: space-between;
}

.post .bottom-row,
.post .bottom-row>div {
	display: flex;
	align-items: center;
	color: rgb(136, 153, 166);
	cursor: pointer;
}

.post .bottom-row>div {
	margin: 8px;
}

.post .bottom-row>div>div {
	border-radius: 9999px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.post .bottom-row>div:hover>div {
	background-color: var(--shadow-color);
}

.bottom-row>div:hover>div i.fa,
.post .bottom-row>div:hover>span {
	color: var(--main-color) !important;
	fill: currentColor;
}

.post .bottom-row>div:nth-child(2):hover>div {
	background-color: rgba(23, 191, 99, 0.2);
}

.bottom-row>div:nth-child(2):hover>div i.fa,
.post .bottom-row>div:nth-child(2):hover>span {
	color: rgb(23, 191, 99) !important;
	fill: currentColor;
}

.post .bottom-row>div:nth-child(3):hover>div {
	background-color: rgba(224, 36, 94, 0.2);
}

.bottom-row>div:nth-child(3):hover>div i.fa,
.post .bottom-row>div:nth-child(3):hover>span {
	color: rgb(224, 36, 94) !important;
	fill: currentColor;
}

.post .bottom-row span {
	font-size: 13px;
}

.post .bottom-row span,

.post .bottom-row i.fa {
	fill: rgb(136, 153, 166);
	color: inherit;
}

.post .bottom-row i.fa{
    margin: 8px;
    width: 1.25em;
    height: 1.25em;
    padding-top: 10px;
}

.post .bottom-row i.custom-icon{
	color: black;
	fill: black; /* Inner color */
    stroke: black; /* Outline color */
    stroke-width: 2px; /* Outline width */
}
.post .bottom-row i.bi {
    margin: 8px;
    width: 1.25em;
    height: 1.25em;
    /* padding-top: 10px; */
}

.post .bottom-row i.comment-icon{
	padding-top: 4px;
	margin: 8px;
	width: 1.25em;
	height: 1.25em;
}
.post .bottom-row span {
	padding: 0 12px;
	line-height: 20px;
}

.search-bar {
	position: relative;
}

.search-bar i.fa {
	position: absolute;
	top: 23px;
	left: 20px;
	color: #022c56;
}


.search {
	border-radius: 30px;
	border: none;
	width: 327px;
	height: 46px;
	background-color: #F6F9FF;
	padding-left: 60px;
}

.search::placeholder {
	font-size: 16px;
}

.trends {
	background-color: #F6F9FF;
	border-radius: 15px;
	margin-top: 12px;
	border-color: #F6F9FF;
}

.trends-header,
.trends-item,
.who-to-follow-header,
.trends-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px;
}

.trends-item i.fa {
	vertical-align: text-bottom;
}

.trends-item {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	cursor: pointer;
	transition-duration: background-color 0.2s;
	padding: 8px;
}

.trends-item>div {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.trends-item span,
.trends-item i.fa {
	font-size: 13px;
	height: 1.25rem;
	color: #022c56 !important;
	fill: #022c56 !important;
}

.trends-item i.fa {
	margin-top: 16px;
}

.trends-item>span {
	margin-top: 4px;
}

.more-btn {
	padding: 4px;
	color: var(--main-color);
	cursor: pointer;
	border-bottom-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.more-btn-ex {
	padding: 10px !important;
}

.follow-btn {
	min-height: 32px;
	min-width: 32px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
	font-weight: bold;
	font-size: 15px;
	padding: 0 1em;
	color: var(--main-color);
	border: 1px solid currentColor;
	border-radius: 9999px;
	background-color: transparent;
}

.follow-btn:hover {
	background-color: var(--shadow-color);
}

.line {
	background-color: rgba(0, 0, 0, 0);
	height: 1px;
	border: 1px solid rgb(25, 39, 52);
	margin: 16px 0;
	border-radius: 16px;
}

.right footer nav {
	padding: 0 16px;
	display: flex;
	flex-wrap: wrap;
}

.right footer span,
.right footer i.fa {
	height: 1em;
	vertical-align: text-bottom;
	margin: 2px 0;
	padding-right: 12px;
	line-height: 16px;
	color: rgb(136, 153, 166);
	fill: currentColor;
	font-size: 13px;
}

.right footer i.fa {
	margin: 0;
}

.right footer span:not(:last-child):hover {
	cursor: pointer;
	text-decoration: underline;
}

.search,
.tweet input {
	color: #000;
}

.top-row .post-image {
	width: 100%;
	height: 35vw !important;
	border-radius: 10px;
	/* border: 1px solid var(--border-color); */
	
}

/* new */

.container-myfeed {
	margin-top: 80px;
	--main-color: rgba(29, 160, 242, 0.525);
	--border-color: rgb(56, 68, 77);
	--shadow-color: rgba(29, 161, 242, 0.1);
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #F0F0F0;
}

.right {
	right: 0;
	border-left: 2px solid #022c5617;
}

.center {
	translate: 50% 0%;
	padding: 0;
}

@media (max-width:992px) {
	.right {
		visibility: collapse;
	}

	.center {
		translate: 20%;
	}


	/* .left-navbar a div span {
		width: 0px !important;
		overflow: hidden !important;
		margin-left: 0px;
		margin-right: 0px;
	} */
	 .left-navbar a div span{
		font-size: xx-small;
	 }

	.left-navbar {
		margin-right: 0px;
		margin-left: 0;
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: start;
		font-size: xx-small;
	}
}

.top-liked{
	margin-top: 15px;
}

.load-more-container {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 20px 0;
}

.load-more-arrow {
    font-size: 2rem;
    color: #007bff;
    transition: transform 0.3s;
}

.load-more-container:hover .load-more-arrow {
    transform: scale(1.1);
}
/* .btn-group .share-logo{
	margin-bottom: 10px;
	
	margin-right: 3px;
	margin-top: 2px;
	
} */

.btn-group .share-logo{
	color: #022c56;
}

.btn-group p.share-text {
	margin-top: 17px;
	color: #022c56;
	padding: 5px;
}

.water-mark{
	margin-bottom: 10px;
	font-size: x-small;
	color: rgb(88, 24, 109);
	margin-left: 5px;
	font-weight: 550;
	text-shadow: 0 0 2px rgba(88, 24, 109, 0.5);

}
.water-mark p{
	text-align: left;
	

}

.post {
    position: relative; /* Ensure the post div is the reference for absolute positioning */
}

.riskometer-overlay {
    position: absolute;
    top: 45%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Offset the element's center */
    z-index: 9900;
    background: white;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* padding: 20px; */
}

.riskometer-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
	z-index: 9999;
}

.riskometer-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}