
  
  .admin-panel-container h1 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #022c56;
    margin-bottom: 2rem;
  }
  
  .admin-panel-container input {
    margin: 1rem 0;
    padding: 0.8rem;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: #495057;
    background-color: #f6f9ff;
    outline: none;
  }
  
  .admin-panel-button {
    margin: 0.4rem;  /* Slightly smaller margin */
    padding: 0.6rem 1.2rem;  /* Slightly smaller padding */
    cursor: pointer;
    background-color: #022c56;  /* Subtle gray color */
    border-radius: 20px;  /* More rounded corners */
    font-size: 0.9rem;  /* Slightly smaller font size */
    font-family: "Montserrat", sans-serif;
    font-weight: 600;  /* Increased font weight for better emphasis */
    color: #f8f9fa;  /* Light color for the text */
    text-transform: uppercase;
    border: none;
    outline: none;
    transition: background-color 0.3s ease-in-out, transform 0.2s ease-in-out;  /* Added transition for transform */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Added subtle shadow */
  }
  
  .admin-panel-button:hover {
    background-color: #366fa8;  /* Slightly darker gray shade on hover */
    transform: scale(1.05);  /* Slight zoom effect on hover */
  }
  
  .admin-panel-button:active {
    background-color: #495057;  /* Darker gray shade when button is pressed */
    transform: scale(1);  /* Reset zoom effect */
  }
  
  
  .response-message {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    color: #495057;
  }
  
  /* User Details */
  .user-details {
    margin-top: 1.5rem;
    border: 1px solid #ced4da;
    border-radius: 10px;
    padding: 1.5rem;
    width: 100%;
  }
  
  .user-details h2 {
    font-size: 1.8rem;
    font-weight: 600;
    color: #022c56;
    margin-bottom: 1.5rem;
  }
  
  .user-details p {
    margin: 0.8rem 0;
    font-size: 1.1rem;
    color: #495057;
    text-align: left;
  }
  
  .user-details p span {
    font-weight: 600;
    color: #022c56;
  }
  
  .vertical-nav {
    width: 100px;
    display: inline-block;
  }
  /* UserAdminPanel.css */

/* UserAdminPanel.css */

 .yes-no-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
 .yes-no-modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #022c56; 
  }
  
  .yes-no-modal-content h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .yes-no-modal-content button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: #022c56;
    border-radius: 20px;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    border: none;
    outline: none;
    transition: background-color 0.2s ease-in-out;
  }
  
  .yes-no-modal-content button:hover {
    background-color: #044485;
  }
   
