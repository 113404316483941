.text-box {
	text-align: center;
	font-size: 17px;
	letter-spacing: 1px;
	line-height: 20px;
}

.text-box h1 {
	font-size: 75px;
	line-height: 160px;
	color: #022c56;
}

.specific-post {
	max-width: 800px;
	margin: 20px auto;
	padding: 20px;
	background: #ffffff;
	border-radius: 10px;
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
	font-family: 'Arial', sans-serif;
	color: #333333;
	line-height: 1.6;
  }
  
  .specific-post h2 {
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 15px;
	color: #444444;
	text-align: center;
  }
  
  .specific-post p {
	margin: 10px 0;
	font-size: 16px;
  }
  
  .specific-post img {
	width: 100%;
	height: auto;
	border-radius: 10px;
	margin: 20px 0;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	object-fit: contain; /* Ensures proper proportion */
  }
  
  .specific-post p span {
	font-weight: bold;
	color: #555555;
  }
  
  .specific-post p:last-child {
	margin-bottom: 0;
  }
  
  .specific-post .tags,
  .specific-post .likes-comments {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 15px;
	font-size: 14px;
	color: #777777;
  }
  
  .specific-post .tags span,
  .specific-post .likes-comments span {
	font-weight: bold;
  }
  
  @media (max-width: 768px) {
	.specific-post {
	  padding: 15px;
	}
  
	.specific-post h2 {
	  font-size: 20px;
	}
  
	.specific-post p {
	  font-size: 14px;
	}
  }
  .login-prompt {
	margin-top: 20px;
	text-align: center;
	font-size: 16px;
	color: #444444;
  }
  
  .login-prompt p {
	margin-bottom: 10px;
  }
  
  .login-button {
	padding: 10px 20px;
	background-color: #007BFF;
	color: white;
	border: none;
	border-radius: 5px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
	background-color: #0056b3;
  }
  
  .login-button:focus {
	outline: none;
  }

@media (max-width: 700px) {
	.text-box h1 {
		font-size: 40px;
		line-height: 80px;
	}
}

.mainbody {
	box-sizing: border-box;
	font-family: 'Rowdies';
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 70vh;
	/* background: linear-gradient(45deg, #ffaf00, #bb02ff); */
	background-attachment: fixed;
}
.content {
	position: relative;
	width: 1150px;
	padding: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}
.content .card {
	position: relative;
	width: 300px;
	height: 420px;
	margin: 20px;
	overflow: hidden;
	background-color: #f6f9ff;
	border: transparent;
	border-radius: 5px;
	box-shadow: 0 30px 50px -20px #ccc;
	animation: loadProfile ease-in-out 1.5s;
}

.content .card:hover {
	background: linear-gradient(to right, #ccc, #fff);
}

.content .card .circle {
	position: relative;
	width: 100%;
	height: 100%;
	background: #000;
	clip-path: circle(160px at center 0);
	text-align: center;
	transition: clip-path 0.3s;
}

.content .card:hover .circle {
	clip-path: circle(170px at center 0);
}

.content .card .circle h2 {
	color: #fff;
	font-size: 4.5em;
	padding: 30px 0;
	transition: font-size 0.3s;
}

.content .card:hover .circle h2 {
	color: linear-gradient(to right, #ccc, #fff);
	font-size: 5em;
}

.content .card .family {
	position: absolute;
	bottom: 10px;
	padding: 15px;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	/* justify-content: center; */
	align-content: center;
}

.content .card .family p{
	color: rgb(102, 102, 102);
}
.content .card .family a{
	position: relative;
    font-weight: 700;
	display: flex;
    align-items: center;
    justify-content: center;
	padding: 5px 0px;
	background: #000;
	border-radius: 5px;
	color: #fff;
	text-decoration: none;
	margin-top: 20px;
}

.content .card:nth-child(1) .circle,
.content .card:nth-child(1) .family a {
	background: linear-gradient(to right, #ffbb28, #fac656);
}
.content .card:nth-child(2) .circle,
.content .card:nth-child(2) .family a {
	background: linear-gradient(to right, #dd427d, rgb(226, 103, 150));
}
.content .card:nth-child(3) .circle,
.content .card:nth-child(3) .family a {
	background: linear-gradient(to right, #cc42ff, #d86dff);
}

/* new */
  .single-person {
	margin: 30px 0;
	padding: 30px;
	background-color: #f6f9ff;
	border-radius: 17px;
	box-shadow: 0 30px 50px -20px #ccc;
	animation: loadProfile ease-in-out 1.5s;
	min-height: calc(100% - 60px);
  }
  
  .single-person:hover {
	background: linear-gradient(to right, #ccc, #fff);
  }
  
  .single-person .person-image {
	position: relative;
	margin-bottom: 50px;
	border-radius: 50%;
	border: 4px dashed transparent;
  }
  
  .single-person:hover .person-image {
	border: 4px dashed #fff;
  }
  
  .single-person .person-image img {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	transition: scale 0.3s;
  }

  .single-person:hover .person-image img {
	scale: 0.8;
  }
  
  .single-person .person-image .icon {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	display: inline-block;
	width: 60px;
	height: 60px;
	line-height: 60px;
	text-align: center;
	background: linear-gradient(to right, #022c56, #ccc);
	color: #fff;
	border-radius: 50%;
	font-size: 24px;
  }
  
  .single-person:hover .person-image .icon {
	background: none;
	background-color: #fff;
	color: #022c56;
  }
  
  .single-person:hover .person-image .icon:hover {
	background: linear-gradient(to right, #022c56, #ccc);
	background-color: none;
  }
  
  .single-person .person-info .full-name {
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 700;
  }
  
  .single-person .person-info .speciality {
	text-transform: uppercase;
	font-size: 12px;
	color: #022c56;
  }
  
   a {
	font-size: 18x;
	color: #022c56;
	text-decoration: none;
  }
  
  @keyframes loadProfile {
	from {
	  transform: translateY(100px);
	  opacity: 0;
	}
	to {
	  transform: translateY(0px);
	  opacity: 1;
	}
  }
  
  @keyframes loadTop {
	from {
	  transform: translateX(100px);
	  opacity: 0;
	}
	to {
	  transform: translateX(0px);
	  opacity: 1;
	}
  }
  
  .btn:hover {
	color: #fff;
  }
  
  .input-text:focus {
	box-shadow: 0px 0px 0px;
	border-color: #022c56;
	outline: 0px;
  }
  
  .form-control {
	border: 1px solid #022c56;
  }
  
  .suggestions {
	margin: 0;
	padding: 0;
	position: relative;
  }
  
  .suggestions li {
	background: white;
	list-style: none;
	border-bottom: 1px solid #d8d8d8;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
	margin: 0;
	padding: 20px;
	transition: background 0.2s;
	display: flex;
	justify-content: space-between;
	text-transform: capitalize;
	cursor: pointer;
  }
  
  span.population {
	font-size: 15px;
  }
  
  .three{
	margin-top: 20px;
	animation: loadTop 1.6s ease-in-out 0.5s;
	animation-fill-mode: both;
  }
  
  .three h1 {
	color: #022c56;
	font-size: 28px;
	font-weight: 500;
	letter-spacing: 0;
	line-height: 1.5em;
	padding-bottom: 15px;
	position: relative;
  }
  
  .three h1:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: 0;
	height: 5px;
	width: 55px;
	background-color: #022c56;
  }
  
  .three h1:after {
	content: "";
	position: absolute;
	left: 0;
	bottom: 2px;
	height: 1px;
	width: 95%;
	max-width: 255px;
	background-color: #022c56;
  }
  
  .advisory-panel {
	margin-left: 72px;
	margin-right: 72px;
	padding-top: 20px;
	padding-bottom: 20px;
  }
  
  .team-container h5 {
	text-align: left;
	margin-bottom: 10px;
	font-weight: 550;
  }
  
  .team-container h4 {
	text-align: left;
	margin-bottom: 20px;
	font-weight: bolder;
  }
  
  .team-container .advisory-row {
	margin-top: 20px;
  }
  
  .team-container .advisory-row h6 {
	font-weight: bold;
  }
  
  .team-container .advisory-row p {
	text-align: justify;
  }
  
  .team-container .advisory-row span {
	font-weight: bold;
  }
  
  .team-container .advisory-row .img-container {
	height: 120px;
	width: 120px;
	overflow: hidden;
	border-radius: 50%; /* Ensures the container is circular */
  }
  
  .team-container .advisory-row .chandan-saurabh {
	height: 100%; /* Adjust to zoom the image */
	width: 100%; /* Adjust to zoom the image */
	object-fit: cover;
	transform: scale(1.1); /* Adjust the scale as needed for zoom effect */
  }

  .team-container .advisory-row .normal-image{
	height: 100%; /* Adjust to zoom the image */
	width: 100%; /* Adjust to zoom the image */
	object-fit: cover;
	transform: scale(1.0); /* Adjust the scale as needed for zoom effect */
  }
/*   
  .feature-scroll-point{
	background-color: white;
  } */