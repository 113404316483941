.riskometer {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
    font-family: Arial, sans-serif;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .gauge {
    position: relative;
    width: 100%;
    height: 150px; /* Increased height to accommodate the text */
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
  
  .gauge svg {
    display: block;
    width: 70%; /* Set SVG width to 70% */
    height: auto;
    margin: auto;
    
  }
  
  .gauge-text {
    position: absolute;
    top: 70%; /* Center the text vertically within the gauge */
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
  }
  
  .stats {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .stat {
    width: 48%;
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }
  