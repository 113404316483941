button {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0;
    cursor: pointer;
}

input {
    background-color: transparent;
    padding: 0;
    border: 0;
    outline: 0;
}

input[type="submit"] {
    cursor: pointer;
}

input::-webkit-input-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input::-moz-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input:-ms-input-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input::-ms-input-placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

input::placeholder {
    font-size: 0.85rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: #ccc;
}

/**
   * Page background
   */
.user {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    height: 85vh;
}

.user_options-container {
    position: relative;
    width: 100%;
}

.user_options-text {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    background-color: #022c56;
    border-radius: 0px;
}

/**
   * Unregistered user box and text
   */
.user_options-unregistered {
    width: 50%;
    padding: 75px 45px;
    color: #fff;
    font-weight: 300;
    animation: loadTop ease-in-out 1.5s;

}

.user_unregistered-title {
    margin-bottom: 15px;
    font-size: 1.66rem;
    line-height: 1em;
}

.user_unregistered-text {
    font-size: 0.83rem;
    line-height: 1.4em;
}

.user_unregistered-signup {
    margin-top: 30px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 10px 30px;
    color: #fff;
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 0.2rem;
    -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.user_unregistered-signup:hover {
    color: #022c56;
    background-color: #fff;
}

/**
   * Login forms
   */
.user_options-forms {
    position: absolute;
    top: 50%;
    left: 40px;
    width: calc(45% - 40px);
    min-height: 420px;
    overflow: hidden;
    background-color: #f6f9ff;
    border: transparent;
    border-radius: 5px;
    box-shadow: 0 30px 50px -20px rgba(204, 204, 204, 0.663);
    -webkit-transform: translate3d(100%, -50%, 0);
    transform: translate3d(100%, -50%, 0);
    -webkit-transition: -webkit-transform 0.4s ease-in-out;
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
}

.user_options-forms .user_forms-login {
    -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out;
}

.user_options-forms .forms_title {
    margin-bottom: 45px;
    font-size: 2.5rem;
    font-weight: 550;
    line-height: 1em;
    text-transform: uppercase;
    color: #022c56;
    letter-spacing: 0.1rem;
}
.input-container {
    position: relative;
    width: 100%;
  }
  .toggle-visibility {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }
.user_options-forms .forms_field:not(:last-of-type) {
    margin-bottom: 20px;
}

.user_options-forms .forms_field{
    
        position: relative;
        display: flex;
        align-items: center;
      
}


.user_options-forms .forms_field-input {
    width: calc(100% - 16px);
    border-bottom: 1px solid #ccc;
    padding: 6px 20px 6px 6px;
    margin-left: 0;
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    color: gray;
    letter-spacing: 0.1rem;
    -webkit-transition: border-color 0.2s ease-in-out;
    transition: border-color 0.2s ease-in-out;
}


  

.user_options-forms .forms_field-input:focus {
    border-color: gray;
}

.user_options-forms .forms_buttons {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 35px;
}

.user_options-forms .forms_buttons-action {
    width: 100%;
    background-color: #022c56;
    border-radius: 3px;
    padding: 10px 35px;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    -webkit-transition: background-color 0.2s ease-in-out;
    transition: background-color 0.2s ease-in-out;
}

.user_options-forms .forms_buttons-action:hover {
    background-color: #044485;
}

.user_options-forms .user_forms-login {
    position: absolute;
    top: 70px;
    left: 40px;
    width: calc(100% - 80px);
    opacity: 0;
    visibility: hidden;
    animation: loadProfile ease-in-out 1.5s;
    -webkit-transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, -webkit-transform 0.5s ease-in-out;
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, transform 0.5s ease-in-out;
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, transform 0.5s ease-in-out, -webkit-transform 0.5s ease-in-out;
}

.user_options-forms .user_forms-login {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
    visibility: visible;
}

/**
   * Responsive 990px
   */
@media screen and (max-width: 990px) {
    .user_options-forms {
        min-height: 350px;
    }

    .user_options-forms .forms_buttons {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    .user_options-forms .user_forms-login .forms_buttons-action {
        margin-top: 30px;
    }

    .user_options-forms .user_forms-login {
        top: 40px;
    }

    .user_options-unregistered {
        padding: 50px 45px;
    }
}

@media (max-width: 768px) {

    .user {
        width: 100vw;
        height: 110vh;
    }

    .user_options-container {
        position: static;
        width: 100vw;
        height: 100vh;
        margin-bottom: 80vh;
    }

    .user_options-forms {
        position: relative;
        height: 60vh;
        width: 100vw;
        top: 30vh;
        left: -100%;
        border-radius: 0px;
        box-shadow: none;
    }

    .user_options-text {
        position: relative;
        height: 40vh;
        width: 100vw;
        top: 100vh;
        left: 0%;
    }

    .user_options-unregistered {
        width: 100%;
    }
}

@keyframes loadProfile {
    from {
        transform: translateY(100px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes loadTop {
    from {
        transform: translateX(100px);
        opacity: 0;
    }

    to {
        transform: translateX(0px);
        opacity: 1;
    }
}

.popup-message {
	color: red;
	
	
	border-radius: 5px;
	text-align: center;
	margin-top: 60%;
    
    
}

