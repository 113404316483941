/* 
   .post-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .post-card {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
    background-color: #fff;
  }
  
  .post-header {
    display: flex;
    align-items: start ;
    padding: 10px 15px;
  }
  
  .post-avatar {
    margin-right: 10px;
  }
  
  .avatar-img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .post-user-info {
    display: flex;
    flex-direction: column;
  }
  
  .post-username {
    font-weight: bold;
    margin: 0;
  }
  
  .post-details {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #666;
    margin-top: 8px;
  }
  
  .post-tags, .post-date {
    margin: 0;
  }
  
  .post-body {
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
  }
  
  .post-media {
    width: 100%;
    height: auto;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  
  .post-text {
    margin: 10px 0;
    align-self: flex-start;
  }
  
  .post-footer {
    display: flex;
    justify-content: space-around;
    padding: 10px 15px;
    border-top: 1px solid #ddd;
  }
  
  .post-action {
    color: #999;
    text-decoration: none;
    font-size: 14px;
  } */
  
  .post-delete-btn {
  
    width: 100px;
    margin: 10px 15px;
    padding: 10px;
    background-color: #dc3545;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
  }
  
  .post-delete-btn:hover {
    background-color: #c82333;
  }
  
  

  .more-btn>p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4px;
  }