body {
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    font-family: Arial, sans-serif;
}

.redirect-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.redirect-content {
    max-width: 600px;
    width: 100%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.redirect-logo {
    text-align: center;
    margin-bottom: 20px;
}

.redirect-logo img {
    max-width: 200px;
    height: auto;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.redirect-button {
    display: block;
    width: 100%;
    padding: 15px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: opacity 0.2s ease;
}

.redirect-button:hover {
    opacity: 0.9;
}

.app-button {
    background-color: #007AFF;
    color: white;
}

.web-button {
    background-color: #34C759;
    color: white;
} 